import React from "react";
import '../New_Project/New_Project.css';
import { useEffect, useState } from "react";
import Nav from "../nav_bar/Nav_bar";
import Site from "../side_bar/side_bar";
import avatar from "../New_Project/avtart.jpg";
import {ImUpload} from "react-icons/im";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import http from "../../axios/http";
import { Button } from "rsuite"; 
import { useNavigate, useParams } from "react-router-dom";
import CheckAdmin from "../CheckAdmin";

const EditProject=(props)=>{

    const { id } = useParams();
    let navigate = useNavigate();
    
        useEffect(() => {
            CheckAdmin()===false ? navigate('/Admin') : console.log();
        
        http.post('Project/Show',{
            id:id,
        }).then(
            res=>{
                console.log(res.data);
                
                setValue('name',res.data.name)
                setValue('text',res.data.text)
                setValue('name_ar',res.data.name_ar)
                setValue('text_ar',res.data.text_ar)
                setValue("website",res.data.website)
                setValue('app_store',res.data.app_store)
                setValue('play_store',res.data.play_store)
                setValue('skills',res.data.skills)
                setImages(res.data.images)
                seticon(res.data.icon)
            
            }
            
        )
    }, []);

     const {
        register,
        handleSubmit,
        setError,
        setValue ,
        formState: { errors},
      } = useForm({
        defaultValues:{
            
            name: '',
            text: '',
            name_ar: '',
            text_ar: '',
            website: null,
            app_store: null,
            play_store: null,
            skills: '',
            
        

        }
    });
    const [file, setfile] = useState("");
    const [icon, seticon] = useState(false);
    const [image, setimage] = useState('');
    console.log(icon);
    
    // =======================================
    const [loading, setloading] = useState(false);

    const [Images, setImages] = useState([]);    

    
    
    const  handel_submit= (data)=>{
        
        setloading(true)
        // data.image=file;
        // data.id=id;

        http.post('Project/Update',{
            'image[]':file,
            name: data.name,
            text: data.text,
            name_ar: data.name_ar,
            text_ar: data.text_ar,
            skills: data.skills,
            website: data.website,
            app_store: data.app_store,
            play_store: data.play_store,
            icon:image,

            id:id,
        }).then(res=>{
            res.status===200 ? toast.success(" Project Edited 👍",{autoClose: 2000,}) : toast.error("ERROR ",{autoClose: 2000,})
            // navigate('/Project')
            setloading(false)            

        }).catch(error=>{
            const errors=error.response.data.errors;
            Object.keys(errors).forEach(key => {
                setError(key, {type: "Error",message: errors[key][0],})    
            });

        })
    }

    return(
        <div className={`New_Project ${props.color==false ? 'dark': ''}` }>
                    <ToastContainer />

            <Site color={props.color} handel_color={props.handel_color} handel_side={props.handel_side}/>
            <div className="contener" id="body">
                <Nav color={props.color} handel_color={props.handel_color} handel_side={props.handel_side}/>
                <div className="top">
                    <h1>Edit Project</h1>
                </div>
                <div className="bottom">
                    <div className="left">
                    {
                            Images.map((image)=>{
                                return (
                                    
                                    <img src={image.image} alt="" />
                                )
                            })
                        }                        {errors.image && <p className="error">{errors.image.message}</p>}
                    
                        <img src={icon} alt="" />

                    </div>
                    <div className="right">
                        <form onSubmit={handleSubmit(handel_submit)}>
                            <div className="form">
                                <div className="input_form">
                                    <label htmlFor="file"> image: <ImUpload className="upload"/></label>
                                    <input type="file" multiple {...register("image")}   id="file" onChange={e=>setfile(e.target.files)} style={{display:"none"}}/>
                                </div>
               
                                         <div className="input_form">
                                           <label htmlFor="icon"> Icon: <ImUpload className="upload"/></label>
                                           <input type="file"  {...register("Icon", { required: 'Icon is required'})}   id="icon"  onChange={e=>setimage(e.target.files[0])} style={{display:"none"}}/>
                                       </div>
               
               
               
               
               
                                <div className="input_form">
                                    <label>website</label>
                                    <input type="url" {...register("website")} placeholder="website URL"/>
                                    {errors.website && <p className="error">{errors.website.message}</p>}

                                </div>
                                <div className="input_form">
                                    <label>Name</label>
                                    <input type="text"  {...register("name")}  placeholder="Name"/>
                                    {errors.name && <p className="error">{errors.name.message}</p>}

                                </div>
                                <div className="input_form ar">
                                    <label>الاسم</label>
                                    <input type="text"  {...register("name_ar")}  placeholder="الاسم بالعربي"/>
                                    {errors.name_ar && <p className="error">{errors.name_ar.message}</p>}

                                </div>
                                <div className="input_form">
                                    <label>App Store</label>
                                    <input type="url" {...register("app_store")} placeholder="App Store URL"/>
                                    {errors.app_store && <p className="error">{errors.app_store.message}</p>}

                                </div>

                                <div className="input_form">
                                    <label>Play Store</label>
                                    <input type="url" {...register("play_store")} placeholder="Play Store URL"/>
                                    {errors.play_store && <p className="error">{errors.play_store.message}</p>}

                                </div>



                                <div className="input_form">
                                    <label>Text</label>
                                    <textarea type="text" {...register("text")} placeholder="Text"/>
                                    {errors.text && <p className="error">{errors.text.message}</p>}
                                
                                </div>
                                <div className="input_form ar">
                                    <label>النص</label>
                                    <textarea type="text" {...register("text_ar")} placeholder="النص بالعربي"/>
                                    {errors.text_ar && <p className="error">{errors.text_ar.message}</p>}
                                
                                </div>
                                <div class='tag'>
                                <label>skills</label>
                                    <textarea type="text" {...register("skills", { required: 'skills AR is required'})} placeholder="skills"/>
                                    {errors.skills && <p className="error">{errors.skills.message}</p>}
                                
                                <button disabled={loading ? true : false} >Update</button>
                                </div>

                                {/* <Button disabled='false' style={ButtonStyle}>sent</Button>   */}

                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default EditProject;