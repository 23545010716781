import React, { useEffect, useState } from "react";  
import { DataGrid } from '@mui/x-data-grid';  
import Nav_bar from "../nav_bar/Nav_bar";  
import Side_bar from "../side_bar/side_bar";  
import "./Slider_Table.css"  
import { Link, useNavigate } from "react-router-dom";  
import http from "../../axios/http";  
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';  
import CheckAdmin from "../CheckAdmin";
  
const Slider_Table = (props) => {  
  const navigate = useNavigate();
  useEffect(() => {  
    
    CheckAdmin()===false ? navigate('/Admin') : console.log();

   http.get('Slider/Get').then(res => {  
    setDATA(res.data);  
   })  
  }, []);  
  
  const [data, setDATA] = useState([]);  
  const [open, setOpen] = useState(false);  
  const [deleteId, setDeleteId] = useState(null);  
  
  const handleDelete = (e) => {  
   setDeleteId(e);  
   setOpen(true);  
  }  
  
  const handleConfirmDelete = () => {  
   http.post('Slider/Delete', {  
    id: deleteId,  
   }).then(  
    res => {  
      console.log(res);  
    }  
   )  
   setDATA(data.filter((item) => item.id !== deleteId));  
   setOpen(false);  
  }  
  
  const handleCancelDelete = () => {  
   setOpen(false);  
  }  
  
  const columns = [  
   { field: 'id', headerName: 'ID', width: 70 },  
   { field: 'image', headerName: 'Image', width: 200, renderCell: (data) => {  
    return (  
      <div className="img_name">  
       <img src={data.row.image} alt="" />  
       {/* <span>{data.row.name}</span> */}  
      </div>  
    )  
   }  
   },  
   { field: 'title', headerName: 'Title', width: 200 },  
   { field: 'title_ar', headerName: 'Title Ar', width: 200 },  
   { field: 'text', headerName: 'Text', width: 500 },  
   { field: 'text_ar', headerName: 'Text AR', width: 200 },  
  
   {  
    field: 'action', headerName: "Action", width: 200,  
    renderCell: (props) => {  
      return (  
       <div className="action">  
        <button><Link to={`/Edit-Slider/${props.id}`}>Edit</Link></button>  
        <button key={props.id} onClick={() => handleDelete(props.id)}>Delete</button>  
       </div>  
      )  
    }  
   }  
  ];  
  
  return (  
   <div className={`main_users ${props.color == false ? 'dark' : ''}`} id="body">  
    <Side_bar color={props.color} handel_color={props.handel_color} handel_side={props.handel_side} />  
    <div className="nav contener user_list_" id="body">  
      <Nav_bar color={props.color} handel_color={props.handel_color} handel_side={props.handel_side} />  
      <div className={`table ${props.color == false ? 'dark' : ''}`} id="body">  
       <div className="add_new_user">  
        <span className="title">add new Slider Text </span>  
        <span><Link to="/Slider">add new</Link></span>  
       </div>  
       <DataGrid  
        rows={data}  
        columns={columns}  
        pageSize={8}  
        rowsPerPageOptions={[2]}  
        checkboxSelection  
       />  
      </div>  
    </div>  
    <Dialog  
      open={open}  
      onClose={handleCancelDelete}  
      aria-labelledby="alert-dialog-title"  
      aria-describedby="alert-dialog-description"  
    >  
      <DialogTitle id="alert-dialog-title">  
       {"Confirm Delete"}  
      </DialogTitle>  
      <DialogContent>  
       <DialogContentText id="alert-dialog-description">  
        Are you sure you want to delete this slider?  
       </DialogContentText>  
      </DialogContent>  
      <DialogActions>  
       <Button onClick={handleCancelDelete}>Cancel</Button>  
       <Button onClick={handleConfirmDelete} autoFocus>  
        Delete  
       </Button>  
      </DialogActions>  
    </Dialog>  
   </div>  
  )  
}  
export default Slider_Table;
