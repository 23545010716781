import React, { useEffect, useState } from "react";
import Cart from "../cart/cart";
import Nav_bar from "../nav_bar/Nav_bar";
import Side_bar from "../side_bar/side_bar";
import List from "../list/List";
import { Link, useNavigate } from "react-router-dom";
import "./Home.css"
import http from "../../axios/http";
import CheckAdmin from "../CheckAdmin";
const Home=(props)=>{
    const navigate = useNavigate();

    useEffect(() => {
        CheckAdmin()===false ? navigate('/Admin') : console.log();

        http.get('Home').then(res=>{
          setData(res.data);  
          console.log(res.data);
                
            
        })
      }, []);
      const [Data, setData] = useState({});
    
    return(
        <div className={`home ${props.color===false ? 'dark' : ''} row g-4 `}>
            
                <Side_bar color={props.color} handel_color={props.handel_color} handel_side={props.handel_side}/>

            
            <div className="contener_home  g-4 mb-4" id="body">
                <Nav_bar color={props.color} handel_color={props.handel_color} handel_side={props.handel_side}/>
                <div className="main_carts  ">

                    <div className="cart"><Link  to="/ContactUS"><Cart count={Data.Contact} type="users"/></Link></div>
                    <div className="cart "><Link to="/Services"><Cart count={Data.Service} type="orders"/></Link></div>
                    <div className="cart "><Link to="/Job"><Cart count={Data.Job} type="earnings"/></Link></div>
                    <div className="cart "><Link to="/Project"><Cart count={Data.Project} type="balance"/></Link></div>
                </div>
           
                <div className="list">
                    <List color={props.color}/>
                </div>
            </div>
        </div>
    )
}
export default Home