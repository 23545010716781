import React from "react";
import './New_Project.css';
import { useEffect, useState } from "react";
import Nav from "../nav_bar/Nav_bar";
import Site from "../side_bar/side_bar";
import avatar from "./avtart.jpg";
import {ImUpload} from "react-icons/im";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import http from "../../axios/http";
import { Button } from "rsuite"; 
import { TagInput } from 'rsuite';
import { Icon } from "@mui/material";
import CheckAdmin from "../CheckAdmin";
import { useNavigate } from "react-router-dom";

const New_Project=(props)=>{
        const navigate = useNavigate();
    
    CheckAdmin()===false ? navigate('/Admin') : console.log();
    
     const {
        register,
        handleSubmit,
        setError,
        setValue ,
        formState: { errors},
      } = useForm({
        defaultValues:{
            
            name: '',
            text: '',
            name_ar: '',
            text_ar: '',
            skills: '',
            website: null,
            app_store: null,
            play_store: null,
        }
    });
    const [file, setfile] = useState("");
    const [icon, seticon] = useState(false);
    // =======================================
    const [loading, setloading] = useState(false);
    


    const  handel_submit= (data)=>{
        setloading(true)
        // data.image=file;



        http.post('Project/Store',{
            'image[]':file,
            name: data.name,
            text: data.text,
            name_ar: data.name_ar,
            text_ar: data.text_ar,
            skills: data.skills,
            website: data.website,
            app_store: data.app_store,
            play_store: data.play_store,
            icon:icon,
        }).then(res=>{
            res.status===200 ? toast.success("New Project added 👍",{autoClose: 2000,}) : toast.error("ERROR ",{autoClose: 2000,})
            setloading(false)            
// =======================Reset data============================================
        setValue('name','')
        setValue('text','')
        setValue('name_ar','')
        setValue('text_ar','')
        setValue("website",'')
        setValue('app_store','')
        setValue('play_store','')
        setValue('skills','')
        setfile('')
        setloading(false)

// =======================Reset data============================================

        }).catch(error=>{
            const errors=error.response.data.errors;
            Object.keys(errors).forEach(key => {
                setError(key, {type: "Error",message: errors[key][0],})    
            });

        })
    }

  
    return(
        <div className={`New_Project ${props.color==false ? 'dark': ''}` }>
                    <ToastContainer />

            <Site color={props.color} handel_color={props.handel_color} handel_side={props.handel_side}/>
            <div className="contener" id="body">
                <Nav color={props.color} handel_color={props.handel_color} handel_side={props.handel_side}/>
                <div className="top">
                    <h1>add new Project</h1>
                </div>
                <div className="bottom">
                    <div className="left">
                    <img src={avatar} alt="" />
                    <img src={icon ? URL.createObjectURL(icon): avatar} alt="" />

                    {errors.image && <p className="error">{errors.image.message}</p>}
                    
                    </div>
                    <div className="right">
                        <form onSubmit={handleSubmit(handel_submit)}>
                            <div className="form">
                                <div className="input_form">
                                    <label htmlFor="file"> image: <ImUpload className="upload"/></label>
                                    <input type="file" multiple {...register("image", { required: 'image is required'})}   id="file" onChange={e=>setfile(e.target.files)} style={{display:"none"}}/>
                                </div>

                                <div className="input_form">
                                    <label htmlFor="icon"> Icon: <ImUpload className="upload"/></label>
                                    <input type="file"  {...register("Icon", { required: 'Icon is required'})}   id="icon"  onChange={e=>seticon(e.target.files[0])} style={{display:"none"}}/>
                                </div>
                                <div className="input_form">
                                    <label>website</label>
                                    <input type="url" {...register("website")} placeholder="website URL"/>
                                    {errors.website && <p className="error">{errors.website.message}</p>}

                                </div>
                                <div className="input_form">
                                    <label>Name</label>
                                    <input type="text"  {...register("name", { required: 'name is required'})}  placeholder="Name"/>
                                    {errors.name && <p className="error">{errors.name.message}</p>}

                                </div>
                                <div className="input_form ar">
                                    <label>الاسم</label>
                                    <input type="text"  {...register("name_ar", { required: 'name Ar is required'})}  placeholder="الاسم بالعربي"/>
                                    {errors.name_ar && <p className="error">{errors.name_ar.message}</p>}

                                </div>
                                <div className="input_form">
                                    <label>App Store</label>
                                    <input type="url" {...register("app_store")} placeholder="App Store URL"/>
                                    {errors.app_store && <p className="error">{errors.app_store.message}</p>}

                                </div>

                                <div className="input_form">
                                    <label>Play Store</label>
                                    <input type="url" {...register("play_store")} placeholder="Play Store URL"/>
                                    {errors.play_store && <p className="error">{errors.play_store.message}</p>}

                                </div>



                                <div className="input_form">
                                    <label>Text</label>
                                    <textarea type="text" {...register("text", { required: 'text is required'})} placeholder="Text"/>
                                    {errors.text && <p className="error">{errors.text.message}</p>}
                                
                                </div>
                                <div className="input_form ar">
                                    <label>النص</label>
                                    <textarea type="text" {...register("text_ar", { required: 'text AR is required'})} placeholder="النص بالعربي"/>
                                    {errors.text_ar && <p className="error">{errors.text_ar.message}</p>}
                                
                                </div>

                                <div class='tag'>
                                <label>skills</label>
                                    <textarea type="text" {...register("skills", { required: 'skills AR is required'})} placeholder="skills"/>
                                    {errors.skills && <p className="error">{errors.skills.message}</p>}
                                
                                <button  disabled={loading ? true : false}>sent</button>
                                </div>

                                {/* <Button disabled='false' style={ButtonStyle}>sent</Button>   */}

                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default New_Project;