import React from "react";
import "./side_bar.css";
import {  Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import {RiDashboardFill} from "react-icons/ri";
import {FaUser,FaAddressCard,FaRegHandPaper, FaNetworkWired} from "react-icons/fa";
import {BsFillQuestionSquareFill, BsLayoutTextSidebarReverse, BsShop} from "react-icons/bs";
import {ImTruck} from "react-icons/im";
import {CgArrowsVAlt} from "react-icons/cg";
import {IoNotifications} from "react-icons/io5";
import {BsFillCloudArrowUpFill,BsGear} from "react-icons/bs";
import {FiLogOut} from "react-icons/fi";
import { GoServer } from "react-icons/go";
import { MdHomeMax } from "react-icons/md";

import { GrContact } from "react-icons/gr";


const Side_bar=(props)=>{
    const navigate = useNavigate();

    const logout=()=>{
        sessionStorage.removeItem('token')
        navigate('/Admin');
    }

    return(
        <div className={`side_bar ${props.color==false ? 'dark': '' }`} id="side_bar" >
            <div className="hedaer">
                <div className="close" id="close" onClick={props.handel_side}>x</div>
                <span><Link to="/" >Alostourh</Link ></span>
            </div>
            <hr/>
            <div className="main">
            <ul>
                        <p>main</p>
                    <li>
                        <RiDashboardFill/>
                        <span><Link to="/">dashboard</Link></span>
                    </li>
                        <p>lists</p>
                    <li>
                        <GoServer />
                        <span><Link to="/Services">Services</Link></span>
                    </li>
                    <li>
                        <BsShop/>
                        <span><Link to="/Project">Project</Link></span>
                    </li>
                    <li>
                    <BsFillQuestionSquareFill />
                    <span><Link to="/Questions">Questions</Link></span>
                    </li>
                    <li>
                        <BsLayoutTextSidebarReverse/>
                        <span><Link to="/About">About</Link></span>
                    </li>
                    <li>
                        <MdHomeMax/>
                        <span><Link to="/Slider_Home">Home</Link></span>
                    </li>
                        <p>useful</p>
                    <li>
                    <GrContact/>
                    <span><Link to="/ContactUS">Contact US</Link></span>
                    </li>
                    <li>
                      <FaNetworkWired/>
                    <span><Link to="/Job">Job</Link></span>

                    </li>
                    <li>
                        <BsGear/>    
                        <span><Link to="/Media">social media</Link></span>
                    </li>
             
                    <li>
                        <FaUser/>
                        <span><Link to="/Profile">profile</Link></span>
                    </li>
                    <li onClick={logout}>
                        <FiLogOut/>
                        <span>logout</span>
                    </li>

                </ul>
                {/* <div className="moods_color" onClick={props.handel_color}>
                    <div className="mood">
                        
                    </div>
                    <div className="mood" onClick={ props.handel_color}>
                        
                    </div>
                </div> */}
            </div>

        </div>
    )
}
export default Side_bar;