import React from "react";
import './New_Question.css';
import { useEffect, useState } from "react";
import Nav from "../nav_bar/Nav_bar";
import Site from "../side_bar/side_bar";

import { useForm } from "react-hook-form";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import http from "../../axios/http";
import { Button } from "rsuite"; 
import CheckAdmin from "../CheckAdmin";
import { useNavigate } from "react-router-dom";

const New_Questions=(props)=>{
        const navigate = useNavigate();
    
    CheckAdmin()===false ? navigate('/Admin') : console.log();

     const {
        register,
        handleSubmit,
        setError,
        setValue ,
        formState: { errors},
      } = useForm({
        defaultValues:{
            
            question: '',
            answer: '',
            question_ar: '',
            answer_ar: '',
 
        }
    });
    // =======================================
    const [loading, setloading] = useState(false);


    
    
    const  handel_submit= (data)=>{
        setloading(true)

        http.post('Questions/Store',data).then(res=>{
            res.status==200 ? toast.success("New Questions added 👍",{autoClose: 2000,}) : toast.error("ERROR ",{autoClose: 2000,})
            setloading(false)            
// =======================Reset data============================================
        setValue('question','')
        setValue('answer','')
        setValue('question_ar','')
        setValue('answer_ar','')
      
        setloading(false)

// =======================Reset data============================================

        }).catch(error=>{
            const errors=error.response.data.errors;
            Object.keys(errors).forEach(key => {
                setError(key, {type: "Error",message: errors[key][0],})    
            });

        })
    }

    return(
        <div className={`New_Questions ${props.color==false ? 'dark': ''}` }>
                    <ToastContainer />

            <Site color={props.color} handel_color={props.handel_color} handel_side={props.handel_side}/>
            <div className="contener" id="body">
                <Nav color={props.color} handel_color={props.handel_color} handel_side={props.handel_side}/>
                <div className="top">
                    <h1>add  New_Questions</h1>
                </div>
                <div className="bottom">
              
                    <div className="right">
                        <form onSubmit={handleSubmit(handel_submit)}>
                            <div className="form">
                                <div className="input_form">
                                    <label>question</label>
                                    <textarea type="text"  {...register("question", { required: 'question is required'})}  placeholder="question"/>
                                    {errors.question && <p className="error">{errors.question.message}</p>}

                                </div>
                                <div className="input_form">
                                    <label>answer</label>
                                    <textarea type="text" {...register("answer", { required: 'answer is required'})} placeholder="answer"/>
                                    {errors.answer && <p className="error">{errors.answer.message}</p>}
                                
                                </div>

                                <div className="input_form ar">
                                    <label>السؤال</label>
                                    <textarea type="text"  {...register("question_ar", { required: 'ادخل السوال بالعربي'})}  placeholder="السؤال بالعربي"/>
                                    {errors.question_ar && <p className="error">{errors.question_ar.message}</p>}

                                </div>
                                <div className="input_form ar">
                                    <label>الاجابه</label>
                                    <textarea type="text" {...register("answer_ar", { required: 'ادخل الاجابه بالعربي'})} placeholder="الاجابه  بالعربي"/>
                                    {errors.answer_ar && <p className="error">{errors.answer_ar.message}</p>}
                                
                                </div>
                                {/* <Button disabled='false' style={ButtonStyle}>sent</Button>   */}

                            </div>
                            <div className="btn">
                            <button  disabled={loading ? true : false}>sent</button>

                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default New_Questions;